import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Grid, StyleRules, WithStyles, withStyles } from '@material-ui/core';

import { AppState } from '../../reducers';
import ConsultantProjectView from './home/ConsultantProjectView';
import ConsultantViewHeader from './home/ConsultantViewHeader';

const styles = (): StyleRules =>
  createStyles({
    box: {
      padding: '2rem',
      margin: '1rem auto',
      background: '#f8f8f8',
    },
  });

const VisitorView = ({ classes }: Props): ReactElement => {
  // const { t } = useTranslation();

  const [professionalValue, setProfessionalValue] = useState<string>('');

  const user = useSelector((appState: AppState) => appState.user);

  const isLogin = useMemo(() => {
    return !!user?.id;
  }, [user?.id]);

  return (
    <Grid xs={12} className={classes.box} container>
      <ConsultantViewHeader
        professionalValue={professionalValue}
        setProfessionalValue={setProfessionalValue}
      />
      <ConsultantProjectView isLogin={isLogin} professionalValue={professionalValue} />
    </Grid>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(VisitorView);
