import React from 'react';

import ConsultantVisitorLayout from '../../layout/ConsultantVisitorLayout';
import VisitorView from '../../views/consultant/VisitorView';

const VisitorPage = (): React.ReactElement => {
  return (
    <>
      <ConsultantVisitorLayout>
        <VisitorView />
      </ConsultantVisitorLayout>
    </>
  );
};

export default VisitorPage;
