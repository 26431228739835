import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';

import Header from '../components/ConsultantHeader';
import Footer from '../components/Footer';
import LoadingLogo from '../components/LoadingLogo';
import { TOKEN_KEY } from '../utils/graphql';

const styles = (): StyleRules =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    content: {
      width: '1350px',
      margin: '0 auto',
    },
  });

const isBrowser = typeof window !== 'undefined';
const ConsultantVisitorLayout = ({ classes, children }: Props): React.ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const token = useMemo(() => {
    return isBrowser ? localStorage.getItem(TOKEN_KEY) : '';
  }, []);

  useEffect(() => {
    if (token) {
      navigate('/consultant');
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [token]);

  return (
    <>
      {isLoading ? (
        <LoadingLogo />
      ) : (
        <>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
          </Helmet>
          <Header />
          <div className={classes.container}>
            <div className={classes.content}>{children}</div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactElement | React.ReactElement[];
}

export default withStyles(styles)(ConsultantVisitorLayout);
